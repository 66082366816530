<template>
  <div class="wrapper">
    <div class="list" v-show="!detail">
      <div class="q-item" v-for="(item, index) in list" :key="index">
        <div @click="openDetail(index, type)" class="item f-row f-justify-between f-align-center">
          <div class="f-row f-align-center">
            <span class="block"></span>
            <span class="txt">{{item.title}}</span>
          </div>
          <span class="arrow-right"></span>
        </div>
      </div>
    </div>
    <div class="detail" v-show="detail" v-if="detail">
      <div class="title f-row f-align-center"><img src="../../assets/images/q.webp"
                                                   alt="" /><span>{{detail.title}}</span></div>
      <div class="content">
        <p v-for="(txt, idx) in detail.content" :key="idx">{{txt}}</p>
      </div>
    </div>
  </div>

</template>

<script>
import { cate, hot } from './data'
import { getUrlQueryString } from '@/utils/util'
export default {
  name: 'helpCat',
  components: {},
  props: {},
  data () {
    return {
      type: '',
      list: [],
      detail: null
    }
  },
  computed: {},
  watch: {},
  created () {
    const id = this.$route.params.id
    const type = this.$route.params.type

    this.type = type
    if (type === 'hot') {
      this.list = hot
      document.title = '热门问题'
    } else {
      for (let key in cate) {
        let item = cate[key]
        if (item.type === type) {
          this.list = item.list
          document.title = item.title
          break
        }
      }
    }
    if (id) {
      this.isDetail = true
      this.detail = this.list[id]
    }
  },
  mounted () { },
  methods: {
    openDetail (id, type) {
      window.location.href = window.location.protocol + '//' + window.location.host + '/helpCat.html?id=' + id + '&type=' + type
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  margin: 45px 40px;
  .list {
    background-color: #fff;
    padding: 45px 30px;
    border-radius: 30px;
    border: 1px solid #fff;
    .item {
      padding: 0 0 52px;
      .block {
        width: 23px;
        height: 23px;
        background: linear-gradient(270deg, #ababab 0%, #f7f7f7 100%);
        border-radius: 100%;
        margin-right: 26px;
      }
      .txt {
        font-size: 40px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #82403e;
      }
      .arrow-right {
        border-top: 2px solid #ababab;
        border-right: 2px solid #ababab;
        width: 12px;
        height: 12px;
        margin-right: 26px;
        transform: rotate(45deg);
      }
    }
  }
  .detail {
    background-color: #fff;
    padding: 45px 30px;
    border-radius: 30px;
    border: 1px solid #fff;

    font-family: PingFangSC-Semibold, PingFang SC;
    color: #82403e;
    .title {
      img {
        width: 60px;
        height: 60px;
        margin-right: 25px;
      }
      span {
        font-size: 45px;
        font-weight: 600;
        line-height: 20px;
      }
    }
    .content {
      margin-top: 50px;
      font-size: 40px;
      font-weight: 400;
      line-height: 70px;
    }
  }
}
</style>
